<div class="onboarding-user-info-step-container">
  <div class="row mb-15">
    <div class="col-12 text-center">
      <div class="block-title font-size-h4 fw-400 mb-20">Profildaten vervollständigen</div>
    </div>
  </div>

  <form [formGroup]="userInfoForm">
    <div class="row">
      <div class="col-12 mb-15">
        <label for="street" class="theme-color">Straße</label>
        <input
          formControlName="street"
          type="text"
          class="form-control form-control-lg"
          id="street"
          name="street"
          autocomplete="off"
          inputmode="text"
        />
        @if (
          userInfoForm?.controls['street'].invalid &&
          (userInfoForm?.controls['street'].dirty || userInfoForm?.controls['street'].touched)
        ) {
          <div class="text-danger">Feld ist erforderlich</div>
        }
      </div>

      <div class="col-6 mb-15">
        <label for="houseNumber" class="theme-color">Hausnummer</label>
        <input
          formControlName="houseNumber"
          type="text"
          class="form-control form-control-lg"
          id="houseNumber"
          name="houseNumber"
          autocomplete="off"
          inputmode="text"
        />
        @if (
          userInfoForm?.controls['houseNumber'].invalid &&
          (userInfoForm?.controls['houseNumber'].dirty || userInfoForm?.controls['houseNumber'].touched)
        ) {
          <div class="text-danger">Feld ist erforderlich</div>
        }
      </div>

      <div class="col-6 mb-15">
        <label for="zip" class="theme-color">Postleitzahl</label>
        <input
          formControlName="zip"
          type="text"
          class="form-control form-control-lg"
          id="zip"
          name="zip"
          autocomplete="off"
          (input)="zipValidation($event)"
          [maxlength]="5"
          [minlength]="5"
          inputmode="numeric"
        />
        @if (
          userInfoForm?.controls['zip'].invalid &&
          (userInfoForm?.controls['zip'].dirty || userInfoForm?.controls['zip'].touched)
        ) {
          <div class="text-danger">Feld ist erforderlich</div>
        }
      </div>

      <div class="col-12 mb-15">
        <label for="city" class="theme-color">Stadt</label>
        <input
          formControlName="city"
          type="text"
          class="form-control form-control-lg"
          id="city"
          name="city"
          autocomplete="off"
          inputmode="text"
        />
        @if (
          userInfoForm?.controls['city'].invalid &&
          (userInfoForm?.controls['city'].dirty || userInfoForm?.controls['city'].touched)
        ) {
          <div class="text-danger">Feld ist erforderlich</div>
        }
      </div>

      <div class="col-12 mb-15">
        <label class="theme-color" for="birthday">Geburtsdatum</label>

        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-lg"
            formControlName="birthday"
            id="birthday"
            name="birthday"
            mask="00.00.0000"
            [showMaskTyped]="true"
            [specialCharacters]="['.']"
            [dropSpecialCharacters]="[]"
            autocomplete="off"
            inputmode="numeric"
          />
          <div class="input-group-append">
            <span class="input-group-text px-10">
              <i class="bi bi-calendar-event theme-color"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col-6 text-left py-2">
        <button
          type="button"
          class="btn btn-rounded theme-secondary-color pointer-cursor"
          (click)="triggerPreviousStep.emit()"
        >
          <i class="bi bi-arrow-left mr-2"></i> Zur&uuml;ck
        </button>
      </div>

      <div class="col-6 text-right">
        <button
          type="button"
          class="btn btn-rounded btn-purple btn-modal"
          [disabled]="userInfoForm?.invalid"
          (click)="saveUserInfo()"
        >
          Weiter
          <i class="bi bi-arrow-right ml-2"></i>

          @if (requestLoading) {
            <span class="ml-10">
              <div class="spinner-border text-white" role="status" style="width: 14px; height: 14px">
                <span class="sr-only">Bitte warten...</span>
              </div>
            </span>
          }
        </button>
      </div>
    </div>
  </form>
</div>
