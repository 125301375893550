<div class="agent-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="closeAdvisorModal.emit()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    <app-advisor-content-view
      [advisorInfo]="advisor"
      [mandateApproval]="mandateApproval"
      [calendarLink]="calendarLink"
      [meetingLink]="meetingLink"
      (triggerPhoneOptions)="openPhoneOptions()"
      (triggerBrokerContract)="openBrokerContractModal()"
    ></app-advisor-content-view>
  </div>
</div>
