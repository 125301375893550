<div class="client-invitation-banner-container">
  <div class="row">
    <div class="col-12 pointer-cursor" (click)="openClientInvitationModal()">
      @if (innerWidth <= 650 || innerWidth >= 1400) {
        <div class="br-16 p-15 invite-friends-card invite-friends-bg" [ngClass]="{ 'scale-bg': scaleLink }">
          <!--          <div class="line"></div>-->

          <div class="row">
            <div class="col-8 d-flex align-items-center min-height-75 text-index">
              <div class="d-flex flex-column align-items-start">
                @if (!scaleLink) {
                  <div class="text-16 font-w700 text-white">Freunde einladen</div>
                } @else {
                  <div class="text-16 font-w700 text-white">Weiterempfehlen & profitieren</div>
                }

                @if (!scaleLink) {
                  <div class="text-white">Lade Freunde ein und empfehle mich weiter.</div>
                } @else {
                  <div class="text-white">Lade Freunde ein und erhalte eine Belohnung...</div>
                }
              </div>
            </div>
          </div>

          <div class="present-image">
            @if (!scaleLink) {
              <img
                ngSrc="https://static-assets.vinlivt.de/icons/premium/purple/320/Gift%202.png"
                width="130"
                height="130"
                alt="vinlivt"
              />
            } @else {
              <img
                ngSrc="https://static-assets.vinlivt.de/icons/premium/real/640x640/Gift%202.png"
                width="130"
                height="130"
                alt="vinlivt"
              />
            }
          </div>
        </div>
      } @else {
        <div
          class="br-16 d-flex align-items-center justify-content-center square-mini-sidebar invite-friends-bg"
          [ngClass]="{ 'scale-bg': scaleLink }"
        >
          @if (!scaleLink) {
            <img
              ngSrc="https://static-assets.vinlivt.de/icons/premium/purple/320/Gift%202.png"
              width="35"
              height="35"
              alt="vinlivt"
            />
          } @else {
            <img
              ngSrc="https://static-assets.vinlivt.de/icons/premium/real/640x640/Gift%202.png"
              width="35"
              height="35"
              alt="vinlivt"
            />
          }
        </div>
      }
    </div>
  </div>
</div>
