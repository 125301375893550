import { Component, HostListener } from '@angular/core';
import { Constructor } from './utils';

const zeroSafeArea: NativeBridge.EdgeInsets = { left: 0, top: 0, right: 0, bottom: 0 };

export default function WithSafeArea<TBase extends Constructor>(Base: TBase) {
  @Component({
    template: '',
    standalone: true,
  })
  class WithSafeArea extends Base {
    private _safeArea: NativeBridge.EdgeInsets = window.native?.safeArea ?? zeroSafeArea;

    protected get safeAreaLeft(): number {
      return this._safeArea.left;
    }

    protected get safeAreaTop(): number {
      return this._safeArea.top;
    }

    protected get safeAreaRight(): number {
      return this._safeArea.right;
    }

    protected get safeAreaBottom(): number {
      return this._safeArea.bottom;
    }

    @HostListener('window:safeAreaChanged', ['$event.detail'])
    public onSafeAreaUpdated(safeArea: NativeBridge.EdgeInsets): void {
      this._safeArea = safeArea;
    }
  }

  return WithSafeArea;
}
