<div
  [ngClass]="{
    shadowed: isOpen,
    'layout-vinhub-tablet': hideHeader,
    'hide-header': hideHeader
  }"
  class="wrapper"
>
  <app-header
    (open)="showMobileUserSidebar($event)"
    [firstLayer]="firstLayer"
    [title]="deepDiveTitle"
    class="header"
  ></app-header>

  <app-sidebar (click)="hideMobileUserSidebar(false)" class="sidebar"></app-sidebar>

  <!--    [@routeAnimations]="prepareRoute(outlet)"-->
  <main
    [style.padding-bottom]="deviceInfo === 'android' ? '40px' : '0'"
    (click)="hideMobileUserSidebar(false)"
    [style.margin-top.px]="setCorrectSafeAreaByDevice()"
    class="body"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</div>

@if (isOpen) {
  <app-user-sidebar (close)="hideMobileUserSidebar($event)" [isOpen]="isOpen"></app-user-sidebar>
}

@if (firstLayer) {
  <app-mobile-bottom-navigation class="bottom-nav"></app-mobile-bottom-navigation>
}
