import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealEstateDetails, WealthAttachments, WealthRealEstateUpdate } from '../model';
import { map } from 'rxjs/operators';
import WithSubscription from '../utils/WithSubscriptions';

@Injectable({
  providedIn: 'root',
})
export class WealthRealEstateService extends WithSubscription(class {}) implements OnDestroy {
  public filteredImages$ = new BehaviorSubject<{ attachmentUrl: string; id: string }[]>([]);
  private attachmentsSubject = new BehaviorSubject<any[]>([]);
  public attachments$ = this.attachmentsSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  public getRealEstateById(id: number): Observable<RealEstateDetails> {
    return this.http
      .get<RealEstateDetails>(`${environment.apiEndpoint}/wealth/b2c/real-estate/${id}`)
      .pipe(tap((res: RealEstateDetails): void => this.updateAttachments(res.attachments)));
  }

  public updateRealEstateById(id: number, realEstate: WealthRealEstateUpdate): Observable<RealEstateDetails> {
    return this.http
      .put<RealEstateDetails>(`${environment.apiEndpoint}/wealth/b2c/real-estate/${id}`, realEstate)
      .pipe(tap((res: RealEstateDetails): void => this.updateAttachments(res.attachments)));
  }

  public deleteRealEstateById(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/wealth/b2c/real-estate/${id}`);
  }

  public uploadImageToRealEstateUrl(url: string, file: File, id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': file.type,
    });
    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      switchMap(() => this.getRealEstateById(id)),
      tap((res: RealEstateDetails): void => this.updateAttachments(res.attachments)),
    );
  }

  public updateAttachments(attachments: WealthAttachments[]): void {
    const allAttachments = attachments.flatMap((attachment) =>
      attachment.properties.map((property) => ({
        attachmentUrl: property.attachmentUrl,
        attachmentType: property.attachmentType,
        attachmentId: String(attachment.attachmentId),
      })),
    );
    this.attachmentsSubject.next(allAttachments);
  }

  public filterImagesByType(type: string): void {
    this.listenToSubject(
      this.attachments$.pipe(
        map((attachments: any[]) =>
          attachments
            .filter((attachment): boolean => attachment.attachmentType === type)
            .map((attachment) => ({
              attachmentUrl: attachment.attachmentUrl,
              id: attachment.attachmentId,
            })),
        ),
      ),
      (filteredImages) => {
        this.filteredImages$.next(filteredImages);
      },
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    // Additional cleanup if needed
  }
}
