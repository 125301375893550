// Wealth model

export class WealthCategory {
  id: number;
  type: string;
  title: string;
  iconClass: string;
  active: boolean;
  subCategories?: WealthSubCategory[];
}

export class WealthSubCategory {
  id: number;
  categoryName?: string;
}

export class WealthType {
  id: number;
  type?: string;
  title?: string;
  iconClass?: string;
  iconUrl?: string;
  active?: boolean;
}

export class WealthAttachments {
  properties: {
    attachmentUrl: string;
    attachmentType: string;
  }[];
  attachmentId: string;
}

export interface ImageToAdd {
  filesize: number;
  file: File;
}

export class WealthCard {
  id: number;
  category: string;
  price: number;
  img?: string;
}

export class UploadedImage {
  imageId: number;
  status: string;
}

export class CarouselImage {
  attachmentUrl: string;
  id: string;
}

// Wealth Real Estate Section

export class WealthRealEstateMainInformation {
  name: string;
  streetNameAndNumber: string;
  zipCode: string;
  cityName: string;
  countryName: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
}

export class WealthRealEstatePurchaseDetails {
  purchaseDate?: string | null;
  purchasePrice: number | null;
  estimateCurrentValue: number;
}

export class WealthRealEstateSpecificDetails {
  propertySize?: number | null;
  livingSpace?: number | null;
  constructionYear?: number | null;
}

export class WealthRealEstateAdditionalInformation {
  note?: string;
  imageToDelete: string[];
}

export class WealthRealEstate {
  category: WealthCategory;
  mainInformation: WealthRealEstateMainInformation;
  purchaseDetails: WealthRealEstatePurchaseDetails;
  specificDetails: WealthRealEstateSpecificDetails;
  additionalInformation: WealthRealEstateAdditionalInformation;
}

export class RealEstateDetails {
  id: number;
  name: string;
  streetNameAndNumber: string;
  zipCode: string;
  cityName: string;
  countryName: string;
  purchaseDate: string;
  purchasePrice: number;
  propertySize: number;
  revenueInPercentage: number;
  livingSpace: number;
  constructionYear: number;
  estimateCurrentValue: number;
  note: string;
  categoryId: number;
  attachments: WealthAttachments[];
  imageToAdd?: [];
  uploadUrls: string[];
  category: {
    id: number;
    categoryName?: string;
  };
}

export class WealthRealEstateUpdate {
  name: string;
  streetNameAndNumber: string;
  zipCode: string;
  cityName: string;
  countryName: string;
  purchaseDate?: string;
  purchasePrice?: number;
  estimateCurrentValue?: number;
  category: WealthCategory;
  propertySize?: number;
  livingSpace?: number;
  constructionYear?: number;
  note?: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
}

// Wealth Jewelry Section

export class WealthJewelry {
  id: number;
  label: string;
  purchaseOption?: {
    purchaseType: string;
    purchasePrice?: string;
    dateOfPurchase?: string;
    currentMarketValue?: number;
    financingInstalment?: string;
    financingStartDate?: string;
    financingEndDate?: string;
  };
  note: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  categoryId: number;
}

export class JewelryDetails {
  id: number;
  label: string;
  revenueInPercentage: number;
  purchaseOption?: {
    purchaseType: string;
    purchasePrice?: string;
    dateOfPurchase?: string;
    currentMarketValue?: number;
    financingInstalment?: string;
    financingStartDate?: string;
    financingEndDate?: string;
  };
  note?: string;
  category: WealthSubCategory;
  attachments: WealthAttachments[];
  imageToAdd: [];
  uploadUrls: string[];
}

export class UpdateJewelryDetails {
  id: number;
  label: string;
  note?: string;
  categoryId: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
  purchaseOption: {
    purchaseType: string;
    purchasePrice?: string;
    dateOfPurchase?: string;
    currentMarketValue?: number;
    financingInstalment?: string;
    financingStartDate?: string;
    financingEndDate?: string;
  };
}

export class WealthJewelryPurchaseDetails {
  purchaseType: CashOption | FinancingOption;
}

// Wealth Precious Metal Section

export class WealthPreciousMetal {
  id: number;
  weight: number;
  purchasePrice?: number;
  dateOfPurchase?: string;
  currentMarketValue?: number;
  note: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  categoryId: number;
}

export class PreciousMetalDetails {
  id: number;
  material: string;
  weight: number;
  details: string;
  purchasePrice: number;
  purchaseDate: string;
  currentMarketValue: number;
  revenueInPercentage: number;
  note: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  uploadUrls: string[];
  category: WealthSubCategory;
}

export class UpdatePreciousMetalDetails {
  id: number;
  weight: number;
  purchasePrice: number;
  dateOfPurchase: string;
  currentMarketValue: number;
  note: string;
  categoryId: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
}

export class WealthPreciousMetalPurchaseDetails {
  purchaseDate?: string | null;
  purchasePrice: number | null;
  currentMarketValue: number;
}

// Wealth Mobility Section

export class WealthMobility {
  category: number;
  mobilityMainInformation?: WealthMobilityMainInformation;
  mobilityPurchaseDetails: WealthMobilityPurchaseDetails;
}

export class MobilityDetails {
  id: number;
  name: string;
  manufacturerName?: string;
  model: string;
  firstRegistrationDate?: string | null;
  mileage: number;
  licensePlateNo: string;
  note?: string;
  revenueInPercentage: number;
  attachments: WealthAttachments[];
  imageToAdd?: [];
  category: WealthSubCategory;
  uploadUrls: string[];
  purchaseOption?: {
    purchaseType: string;
    purchasePrice?: string;
    dateOfPurchase?: string;
    currentMarketValue?: number;
    leasingStartDate?: string;
    leasingEndDate?: string;
    leasingInstalment?: string;
    financingInstalment?: string;
    financingStartDate?: string;
    financingEndDate?: string;
  };
}

export class WealthMobilityMainInformation {
  name: string | null;
  manufacturerName?: string | null;
  model: string;
  firstRegistrationDate?: string | null;
  mileage: number;
  licensePlateNo?: string | null;
  imageToAdd?: Array<ImageToAdd> = new Array<ImageToAdd>();
  type?: string;
}

export class WealthMobilityPurchaseDetails {
  purchaseType: CashOption | LeasingOption | FinancingOption;
}

export class UpdateMobilityDetails {
  id: number;
  name: string;
  manufacturerName?: string;
  model: string;
  firstRegistrationDate?: string | null;
  mileage: number;
  licensePlateNo: string;
  note?: string;
  categoryId: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
  purchaseOption: {
    purchaseType: string;
    purchasePrice?: string;
    dateOfPurchase?: string;
    currentMarketValue?: number;
    leasingStartDate?: string;
    leasingEndDate?: string;
    leasingInstalment?: string;
    financingInstalment?: string;
    financingStartDate?: string;
    financingEndDate?: string;
  };
}

export interface CashOption {
  purchaseType: 'Cash';
  purchasePrice: string;
  dateOfPurchase: string;
  currentMarketValue: number;
}

export interface LeasingOption {
  purchaseType: 'Leasing';
  leasingStartDate: string;
  leasingEndDate: string;
  leasingInstalment: string;
  currentMarketValue: number;
}

export interface FinancingOption {
  purchaseType: 'Financing';
  financingInstalment: string;
  financingStartDate: string;
  financingEndDate: string;
  currentMarketValue: number;
}

// Art Section

export class WealthArt {
  id: number;
  label: string;
  description: string;
  purchasePrice?: string;
  purchaseDate?: string;
  currentMarketValue?: number;
  note?: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  categoryId: number;
}

export class ArtDetails {
  id: number;
  label: string;
  description: string;
  purchasePrice: number;
  purchaseDate: string;
  currentMarketValue: number;
  revenueInPercentage: number;
  note: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  uploadUrls: string[];
  category: WealthSubCategory;
}

export class UpdateArtDetails {
  id: number;
  label: string;
  description: string;
  purchasePrice: number;
  purchaseDate: string;
  currentMarketValue: number;
  note: string;
  categoryId: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
}

export class WealthArtPurchaseDetails {
  purchaseDate?: string | null;
  purchasePrice: number | null;
  currentMarketValue: number;
}

// Other Section

export class WealthOther {
  id: number;
  label: string;
  description: string;
  purchasePrice?: string;
  purchaseDate?: string;
  currentMarketValue?: number;
  note?: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  categoryId: number;
}

export class OtherDetails {
  id: number;
  label: string;
  description: string;
  purchasePrice: number;
  purchaseDate: string;
  currentMarketValue: number;
  revenueInPercentage: number;
  note: string;
  attachments: WealthAttachments[];
  imageToAdd: [];
  uploadUrls: string[];
  category: WealthSubCategory;
}

export class UpdateOtherDetails {
  id: number;
  label: string;
  description: string;
  purchasePrice: number;
  purchaseDate: string;
  currentMarketValue: number;
  note: string;
  categoryId: string;
  imageToAdd: Array<ImageToAdd> = new Array<ImageToAdd>();
  imageToDelete?: string[];
}

export class WealthOtherPurchaseDetails {
  purchaseDate?: string | null;
  purchasePrice: number | null;
  currentMarketValue: number;
}
