import { Component, HostListener, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ClientInvitationModalComponent } from '../../modals/client-invitation-modal/client-invitation-modal.component';

import { Advisor } from '../../model';

@Component({
  standalone: true,
  selector: 'app-client-invitation-banner',
  templateUrl: 'client-invitation-banner.component.html',
  styleUrls: ['client-invitation-banner.component.scss'],
  imports: [NgOptimizedImage, CommonModule],
})
export class ClientInvitationBannerComponent {
  @Input() advisorInfo: Advisor = new Advisor();
  @Input() scaleLink: string = '';

  protected innerWidth: number = window.innerWidth;

  constructor(private bsModalService: BsModalService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.windowSize();
  }

  private windowSize(): void {
    this.innerWidth = window.innerWidth;
  }

  protected openClientInvitationModal(): void {
    if (!this.scaleLink) {
      const clientInvitationModalRef = this.bsModalService.show(ClientInvitationModalComponent, {});
      clientInvitationModalRef.content.advisorInfo = this.advisorInfo;
    } else {
      window.open(this.scaleLink, '_blank');
    }
  }
}
