<div
  (click)="showAdvisorModal()"
  class="advisor-micro-card-container theme-background-block-bg theme-border d-flex align-items-center justify-content-between gap-1 pointer-cursor"
>
  <div class="d-flex align-items-center">
    <button class="btn btn-circle circle-icon-btn">
      @if (loading) {
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            width: '39px',
            height: '39px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      } @else {
        @if (advisorInfo?.profilePictureUrl) {
          <img class="image-advisor" [src]="advisorInfo.profilePictureUrl" alt="advisor image" />
        } @else {
          <img class="image-advisor" src="https://static-assets.vinlivt.de/img/misc/fox.png" alt="avatar" />
        }
      }
    </button>

    <div class="d-flex flex-column ml-20">
      @if (loading) {
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            width: '80px',
            height: '12px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      } @else {
        <div class="parent-truncate">
          <div class="text-purple child-truncate text-15">
            {{ advisorInfo?.firstName }}&nbsp;{{ advisorFullName ? advisorInfo?.lastName : '' }}
          </div>
        </div>
      }

      <!--      @if (mandateApproval?.status === 'OPEN' && showRightImg) {-->
      <!--        <div class="theme-secondary-color" style="font-size: 12px">Maklermandat unterschreiben</div>-->
      <!--      }-->

      <!--      @if (mandateApproval?.status !== 'OPEN' || !showRightImg) {-->
      <div class="theme-secondary-color text-12">Finanzexperte</div>
      <!--      }-->
    </div>
  </div>

  <!--  @if (mandateApproval?.status === 'OPEN' && showRightImg) {-->
  <!--    <img-->
  <!--      class="mr-15"-->
  <!--      src="https://static-assets.vinlivt.de/icons/premium/purple/320/Shield 1.png"-->
  <!--      height="28"-->
  <!--      alt="shield"-->
  <!--    />-->
  <!--  }-->

  <!--  @if (mandateApproval?.status !== 'OPEN' || !showRightImg) {-->
  <i class="bi bi-chat-dots text-purple text-20 mr-15"></i>
  <!--  }-->
</div>
