<div class="advisor-check-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="action.emit(null)">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    @if (backgroundInformation) {
      <div class="row">
        <div class="col-12">
          <!-- Background Information Block -->
          <app-background-information
            [title]="title"
            [companyId]="company?.id"
            [description]="description"
            [consentType]="consentType"
            (hideBackgroundInformation)="showBackGroundInformation($event, null)"
          ></app-background-information>
        </div>
      </div>
    } @else {
      <div class="row">
        <div class="col-12 text-center mb-20">
          <img
            ngSrc="https://static-assets.vinlivt.de/icons/premium/purple/320/Heart 1.png"
            class="img-fluid animate__animated animate__bounce"
            width="80"
            height="80"
            alt="bookmark"
          />
        </div>

        <div class="col-12 text-center mb-20">
          <div class="section-title">Herzlich Willkommen!</div>
        </div>

        <div class="col-12 text-center mb-30">
          <div class="font-size-h6 font-w700 theme-color">Gleich bist du startklar.</div>
        </div>

        <div class="col-12 text-center mb-30">
          <div class="theme-secondary-color text-12">
            Durch Klicken auf "Akzeptieren" erkl&auml;rst du dich mit der
            <a
              class="text-primary pointer-cursor"
              (click)="showBackGroundInformation(true, consent.PARTNER_INITIAL_INFORMATION)"
              >Erstinformation</a
            >, sowie der
            <a
              href="javascript:void(0);"
              (click)="showBackGroundInformation(true, consent.PARTNER_PRIVACY_CLIENT)"
              class="text-primary"
              >Datenschutzinformation</a
            >
            gegen&uuml;ber der <b>{{ company.name }}</b> freiwillig einverstanden. Das kannst du jederzeit widerrufen.
          </div>
        </div>

        <div class="col-12">
          <button
            class="btn btn-block btn-purple btn-rounded d-flex align-items-center justify-content-center mt-15 btn-modal"
            id="accept-consent-btn"
            (click)="action.emit(true)"
          >
            <span class="mr-5">
              <img
                style="filter: invert(100%) sepia(0%) saturate(4369%) hue-rotate(55deg) brightness(107%) contrast(116%)"
                src="https://static-assets.vinlivt.de/icons/line-icons/svg/login.svg"
                alt="login icon"
              />
            </span>
            Okay los geht’s
          </button>
        </div>

        <div class="col-12">
          <button class="btn btn-block btn-rounded purple-outline-btn mt-15 btn-modal" (click)="action.emit(false)">
            Nein danke ausloggen
          </button>
        </div>
      </div>
    }
  </div>
</div>
