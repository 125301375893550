export class BankConnectionDto {
  id: number;
  bankName: string;
  bankLogo: string;
  bankIcon: string;
  loginFailure: boolean;
  consentExpiration: Date;
  categorizationInProgress: boolean;
  accounts: BankAccount[];
  allSelected?: boolean;
}

export class BankAccount {
  id: number;
  name: string;
  iban: string;
  bankIconUrl: string;
  balance: number;
  visible: boolean;
  selected?: boolean;
}

export class BankAccountTransactionsRequest {
  account_id?: number[];
  analysisId?: number;

  mainCategoryGermanName?: string;
  categoryGroup?: string;

  from?: string;
  to?: string;

  onlyContracts?: boolean;
  minAmount?: string;
  maxAmount?: string;
  orderBy?: string;

  tagIds: number[];

  query?: string;
  counterpartName?: string;

  hasAttachment?: boolean = null;

  descending?: boolean;
  page: number = 0;
  page_size: number = 50;
}

export class BankAccountTransactionsResponse {
  page: number;
  pageSize: number;
  total: number;
  data: BankAccountTransaction[];
  totalPages: number;
}

export class BankAccountTransaction {
  id: number;
  date: Date;
  amount: number;
  counterpartName: string;
  purpose: string;
  categoryName: string;
  categoryTag: string;
  categoryId: number;
  parentCategoryName: string;
  parentCategoryId: number;
  logoUrl: string;
  tagIds: number[];
  attachmentCount: number;
}

export interface TransactionTag {
  id: number;
  name: string;
  selected?: boolean;
}

export class BankCardManagerData {
  totalBalance: number;
  bankConnections: BankConnectionDto[];
  latestTransactions: BankAccountTransactionsResponse;
}

export class Thresholds {
  balanceThreshold: number;
  transactionThreshold: number;
  censoredPushNotification: boolean;
}

export class UpdateAccountNameAndThresholds {
  name?: string;
  balanceWarningThreshold?: number;
  transactionWarningThreshold?: number;
  censorPushNotification?: boolean;
  visible?: boolean;
}
