import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { UiChangeTriggersService } from './shared/global-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    localeService: BsLocaleService,
    private readonly router: Router,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    localeService.use('de');

    if ('native' in window) {
      this.loadNativeStyles();
    }
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.uiChangeTriggersService.init();

    window.native?.registerPushNotificationHandler((data) => {
      switch (data.action) {
        case 'openChat':
          // TODO: start using chatId by -> data.arguments.chatId: string
          this.router.navigate(['/vinhub']);
          break;

        case 'openBankAccount':
          this.router.navigate(['/bank-card-manager', data.arguments.accountId]);
          break;

        default:
          return;
      }
    });
  }

  private loadNativeStyles(): void {
    const head = this.document.getElementsByTagName('head')[0];

    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `assets/scss/native/${window.native.operatingSystem}.css`;

    head.appendChild(style);
  }
}
