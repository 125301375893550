import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { WebSocketService } from '@vinlivt/websocket';
import { NgClass, NgOptimizedImage } from '@angular/common';

import { AUTH_SERVICE_TOKEN, AuthService, VinhubService } from '../../service';

import { VinhubMessage } from '../../model';

import WithSubscription from '../../utils/WithSubscriptions';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-unread-messages-widget',
  templateUrl: 'unread-messages-widget.component.html',
  styleUrls: ['unread-messages-widget.component.scss'],
  imports: [NgOptimizedImage, NgClass, RouterLink],
})
export class UnreadMessagesWidgetComponent extends WithSubscription(Object) implements OnInit {
  public unreadMessages: number = 0;
  public innerWidth: number = window.innerWidth;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private vinhubService: VinhubService,
    private webSocketService: WebSocketService,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.windowSize();
  }

  private windowSize(): void {
    this.innerWidth = window.innerWidth;
  }

  public ngOnInit(): void {
    this.vinhubService.checkUnreadMessages().subscribe({
      next: (data: number): void => {
        this.unreadMessages = data;
      },
      error: (error): void => {
        console.error(error);
      },
    });

    this.listenForNewMessages();
  }

  private listenForNewMessages(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<number>('chat/changed-unread-count'),
      (messageCount: number): void => {
        this.unreadMessages = messageCount;
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('chat/new-message'),
      (message: VinhubMessage): void => {
        if (message.authorId !== this.authService.userId) {
          this.unreadMessages++;
        }
      },
    );
  }
}
