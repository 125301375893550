<div class="contract-start-step-container">
  <div class="fixed-height d-flex flex-column justify-content-center">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="https://static-assets.vinlivt.de/icons/premium/purple/320/Folder 1.png"
            width="120"
            style="min-width: 120px"
            alt="folder image"
          />
        </div>
      </div>
    </div>

    <div class="row mt-50 mb-15">
      <div class="col-12 d-flex justify-content-center">
        <div class="font-size-h4 theme-color">Maklermandat</div>

        <i
          class="bi bi-info-circle ml-10 text-purple pointer-cursor"
          [popover]="
            'Wir benötigen bitte deine Einwilligung, damit wir deine Versicherungsdaten bei den Versicherern erfragen und für dich digitalisieren dürfen.'
          "
          placement="top"
          triggers="mouseenter:mouseleave"
          containerClass="br-16"
        ></i>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="theme-secondary-color text-center title-padding">
          Digitalisierung deiner Versicherungen & Vertretung deiner Interessen gegenüber den Versicherungen.
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-30">
    <div class="col-6 d-flex align-items-center">
      <button class="btn btn-noborder theme-secondary-color text-16-mobile" (click)="triggerSkipContract.emit()">
        Sp&auml;ter
      </button>
    </div>

    <div class="col-6 text-right">
      <button class="btn btn-rounded btn-purple py-15 px-20" id="start-mandate-modal" (click)="triggerNextStep.emit()">
        Los geht’s
      </button>
    </div>
  </div>
</div>
