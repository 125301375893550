import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

import { Advisor, MandateApproval } from '../../model';

@Component({
  standalone: true,
  selector: 'app-advisor-content-view',
  templateUrl: 'advisor-content-view.component.html',
  styleUrls: ['advisor-content-view.component.scss'],
  imports: [RouterLink, CommonModule],
})
export class AdvisorContentViewComponent {
  @Input() advisorInfo: Advisor;
  @Input() mandateApproval: MandateApproval;
  @Input() calendarLink: string;
  @Input() meetingLink: string;

  @Output() triggerPhoneOptions = new EventEmitter();
  @Output() triggerBrokerContract = new EventEmitter();

  constructor() {}
}
