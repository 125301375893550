<nav class="sidebar-content-layout">
  <div class="d-flex flex-column justify-content-between align-items-center br-16 theme-border h-100 overflow-hidden">
    <div class="w-100 pt-20">
      <div class="row mb-30">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <a routerLinkActive="active" routerLink="/dashboard" data-toggle="layout">
            @if (logo) {
              <img class="logo-img" [src]="logo" alt="logo" />
            }
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul class="nav-main px-3">
            <li>
              <a
                class="d-flex align-items-center"
                data-toggle="layout"
                routerLink="/dashboard"
                routerLinkActive="active"
              >
                <img
                  alt="home icon"
                  class="nav-icon"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/home.svg"
                />

                @if (innerWidth >= 1400) {
                  <span class="sidebar-mini-hide font-w400">&Uuml;bersicht</span>
                }
              </a>
            </li>

            <li>
              <a
                class="d-flex align-items-center"
                data-action="layout"
                data-toggle="layout"
                routerLink="/contracts"
                routerLinkActive="active"
              >
                <img
                  alt="contracts icon"
                  class="nav-icon"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/folder.svg"
                />

                @if (innerWidth >= 1400) {
                  <span class="sidebar-mini-hide font-w400">Vertr&auml;ge</span>
                }
              </a>
            </li>

            <li>
              <a
                class="d-flex align-items-center"
                data-toggle="layout"
                routerLink="/bank-card-manager"
                routerLinkActive="active"
              >
                <img
                  alt="wallet icon"
                  class="nav-icon"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/wallet.svg"
                />

                @if (innerWidth >= 1400) {
                  <span class="sidebar-mini-hide font-w400">Konten</span>
                }
              </a>
            </li>
            <li>
              <a class="d-flex align-items-center" routerLinkActive="active" routerLink="/wealth" data-toggle="layout">
                <img
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart%204.svg"
                  class="nav-icon"
                  alt="wallet icon"
                />

                @if (innerWidth >= 1400) {
                  <span class="sidebar-mini-hide font-w400">Vermögen</span>
                }
              </a>
            </li>

            <li>
              <a class="d-flex align-items-center" data-toggle="layout" routerLink="/vinhub" routerLinkActive="active">
                <img
                  alt="chat icon"
                  class="nav-icon"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/chat.svg"
                />

                @if (innerWidth >= 1400) {
                  <span class="sidebar-mini-hide font-w400">Chats</span>

                  @if (unreadMessages > 0) {
                    <!--                  <div class="unread-messages-bubble"></div>-->
                    <span class="ml-auto read-circle text-10">{{ unreadMessages > 10 ? '10+' : unreadMessages }}</span>
                  }
                } @else {
                  @if (unreadMessages > 0) {
                    <span class="read-circle"></span>
                  }
                }
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="bottom-content w-100 px-3">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center justify-content-center mb-2">
          @if (innerWidth >= 1400) {
            <app-advisor-micro-card
              class="mb-15"
              [advisorInfo]="advisorInfo"
              [loading]="advisorLoading"
              [mandateApproval]="person?.mandateApproval"
            ></app-advisor-micro-card>
          }

          @if (companySettings?.showReferralInvitation) {
            <app-client-invitation-banner
              [advisorInfo]="advisorInfo"
              [scaleLink]="scaleLink"
            ></app-client-invitation-banner>
          }
        </div>
      </div>
    </div>
  </div>
</nav>
