import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ImageToAdd } from '../model';

@Injectable({
  providedIn: 'root',
})
export class WealthService {
  private apiEndpoint = `${environment.apiEndpoint}/wealth/b2c`;

  constructor(private http: HttpClient) {}

  public getAggregatedAssets(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/wealth/b2c/aggregated-assets`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error fetching aggregated assets:', error);
        return error;
      }),
    );
  }

  public saveWealthData(wealthType: string, data: any): Observable<any> {
    const endpoint = this.getEndpoint(wealthType);

    const dataToSend = {
      ...data,
      imageToAdd: data.imageToAdd.map((img: ImageToAdd) => ({ filesize: img.filesize })),
    };
    return this.http.post<any>(endpoint, dataToSend).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error saving wealth data:', error);
        return throwError(() => new Error('Failed to save wealth data'));
      }),
    );
  }

  private getEndpoint(wealthType: string): string {
    const endpoints = {
      'real-estate': `${this.apiEndpoint}/real-estate`,
      vehicle: `${this.apiEndpoint}/vehicle`,
      jewelry: `${this.apiEndpoint}/jewelry`,
      'precious-metal': `${this.apiEndpoint}/precious-metal`,
      art: `${this.apiEndpoint}/art`,
      other: `${this.apiEndpoint}/other`,
      // Add more wealth types and their corresponding endpoints here
    };

    const endpoint = endpoints[wealthType];
    if (!endpoint) {
      throw new Error(`Unknown wealth type: ${wealthType}`);
    }

    return endpoint;
  }
}
