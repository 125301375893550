<div class="onboarding-advisor-step-container">
  <div class="centered-content d-flex flex-column align-items-center justify-content-center" style="min-height: 518px">
    <div class="row">
      <div class="col-12 text-center">
        @if (loading) {
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              width: '150px',
              height: '21px',
              'margin-bottom': '0'
            }"
          ></ngx-skeleton-loader>
        } @else {
          <div class="section-title">Hallo {{ person.firstname }}</div>
        }
      </div>
    </div>

    <div class="row">
      <!-- Devider -->
      <div class="col-12 d-flex justify-content-center py-20">
        <div class="content-devider"></div>
      </div>

      <div class="col-12 text-center">
        @if (loading) {
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              width: '50px',
              height: '50px'
            }"
          >
          </ngx-skeleton-loader>
        } @else {
          @if (person?.advisor?.profilePictureUrl) {
            <img
              style="border-radius: 50%"
              src="{{ person.advisor.profilePictureUrl }}"
              width="80"
              height="80"
              alt="avatar"
            />
          } @else {
            <img
              style="border-radius: 50%"
              src="https://static-assets.vinlivt.de/img/misc/fox.png"
              width="80"
              height="80"
              alt="avatar"
            />
          }
        }
      </div>

      <div class="col-12 text-center my-15">
        <div class="theme-secondary-color">Dein Experte</div>
      </div>

      <div class="col-12 text-center">
        <div class="theme-color text-18 font-w700">
          {{ person?.advisor?.firstName + ' ' + person?.advisor?.lastName }}
        </div>
      </div>

      <!-- Devider -->
      <div class="col-12 d-flex justify-content-center py-20">
        <div class="content-devider"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-30 text-center">
        <div class="theme-secondary-color">
          Ich möchte dich gerne besser kennenlernen, es dauert nur ein paar Sekunden deiner Zeit.
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-10">
    <div class="col-12">
      <button class="btn btn-block btn-purple btn-rounded btn-modal" (click)="triggerNextStep.emit()">
        <i class="bi bi-arrow-right mr-2"></i>
        <span>Los geht’s</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-rounded theme-secondary-color pointer-cursor" (click)="bsModalRef.hide()">
        <span>Später</span>
      </button>
    </div>
  </div>
</div>
