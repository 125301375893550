<div class="advisor-content-view-container">
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center">
      @if (advisorInfo?.profilePictureUrl) {
        <img class="advisor-img" [src]="advisorInfo.profilePictureUrl" alt="advisor image" />
      } @else {
        <img class="advisor-img" src="https://static-assets.vinlivt.de/img/misc/fox.png" alt="advisor image" />
      }
    </div>

    <div class="col-12 d-flex align-items-center justify-content-center mt-15 mb-10">
      <div class="text-18 font-w700 theme-color">{{ advisorInfo?.firstName }} {{ advisorInfo?.lastName }}</div>
    </div>

    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="theme-secondary-color text-16">
        {{ advisorInfo?.company?.name }}
      </div>
    </div>
  </div>

  <div class="row mt-30">
    <div class="col-6 pr-2">
      <a
        class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
        routerLink="/vinhub"
      >
        <i class="bi bi-chat-dots text-20"></i>

        <span class="ml-2">Chatten</span>
      </a>
    </div>

    <div class="col-6 d-flex flex-column align-items-center pl-2">
      <a
        class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
        (click)="triggerPhoneOptions.emit()"
      >
        <i class="bi bi-phone text-20"></i>

        <span class="ml-2">Anrufen</span>
      </a>
    </div>

    <div class="col-6 pr-2">
      <a
        class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
        href="mailto:{{ advisorInfo?.email }}"
      >
        <i class="bi bi-envelope text-20"></i>

        <span class="ml-2">E-Mail</span>
      </a>
    </div>

    @if (calendarLink) {
      <div class="col-6 d-flex flex-column align-items-center pl-2">
        <a
          [href]="calendarLink"
          target="_blank"
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
        >
          <i class="bi bi-calendar-check text-20"></i>

          <span class="ml-2">Termin</span>
        </a>
      </div>
    }

    @if (
      mandateApproval?.status === 'OPEN' ||
      mandateApproval?.status?.startsWith('REJECTED') ||
      mandateApproval?.status === 'REVOCATION_CONFIRMED'
    ) {
      <div [ngClass]="{ 'col-12': !meetingLink, 'col-6 px-2': meetingLink }">
        <a
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          (click)="triggerBrokerContract.emit()"
        >
          <i class="bi bi-shield-check text-20"></i>

          <span class="ml-2">Mandat</span>
        </a>
      </div>
    }

    @if (
      mandateApproval?.status === 'PENDING' ||
      mandateApproval?.status === 'EXIST_ON_POOL_SITE' ||
      mandateApproval?.status === 'APPROVED' ||
      mandateApproval?.status === 'REVOCATION_REQUESTED'
    ) {
      <div [ngClass]="{ 'col-12': !meetingLink, 'col-6 px-2': meetingLink }">
        <a
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          (click)="triggerBrokerContract.emit()"
        >
          @if (mandateApproval?.status === 'REVOCATION_REQUESTED') {
            <i class="bi bi-clock text-20"></i>
          }

          @if (mandateApproval?.status !== 'REVOCATION_REQUESTED') {
            <i class="bi bi-file-earmark-arrow-down text-20"></i>
          }

          <span class="ml-2">Mandat</span>
        </a>
      </div>
    }

    @if (meetingLink) {
      <div class="col-6 d-flex flex-column align-items-center pl-2">
        <a
          [href]="meetingLink"
          target="_blank"
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
        >
          <i class="bi bi-camera-video text-20"></i>

          <span class="ml-2">Meeting</span>
        </a>
      </div>
    }
  </div>

  @if (advisorInfo?.company?.companyLogoUrl) {
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <img class="" [src]="advisorInfo.company.companyLogoUrl" alt="company logo" height="50" />
      </div>
    </div>
  }
</div>
