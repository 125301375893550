<div class="data-verification-step-container">
  <form [formGroup]="dataVerificationForm" (ngSubmit)="checkData()">
    <div class="scroll-container d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-12 mb-15">
          <label for="firstName" class="theme-color">Vorname</label>
          <input
            formControlName="firstName"
            type="text"
            class="form-control form-control-lg"
            id="firstName"
            name="firstName"
            autocomplete="off"
          />

          @if (
            dataVerificationForm.controls['firstName'].invalid &&
            (dataVerificationForm.controls['firstName'].dirty || dataVerificationForm.controls['firstName'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-12 mb-15">
          <label for="lastName" class="theme-color">Nachname</label>
          <input
            formControlName="lastName"
            type="text"
            class="form-control form-control-lg"
            id="lastName"
            name="lastName"
            autocomplete="off"
          />

          @if (
            dataVerificationForm.controls['lastName'].invalid &&
            (dataVerificationForm.controls['lastName'].dirty || dataVerificationForm.controls['lastName'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-8 mb-15">
          <label for="street" class="theme-color">Straße</label>
          <input
            formControlName="street"
            type="text"
            class="form-control form-control-lg"
            id="street"
            name="street"
            autocomplete="off"
          />

          @if (
            dataVerificationForm.controls['street'].invalid &&
            (dataVerificationForm.controls['street'].dirty || dataVerificationForm.controls['street'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-4 mb-15">
          <label for="houseNumber" class="theme-color">Hausnummer</label>
          <input
            formControlName="houseNumber"
            type="text"
            class="form-control form-control-lg"
            id="houseNumber"
            name="houseNumber"
            autocomplete="off"
          />

          @if (
            dataVerificationForm.controls['houseNumber'].invalid &&
            (dataVerificationForm.controls['houseNumber'].dirty || dataVerificationForm.controls['houseNumber'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-4 mb-15">
          <label for="zip" class="theme-color">Postleitzahl</label>
          <input
            formControlName="zip"
            type="text"
            class="form-control form-control-lg"
            id="zip"
            name="zip"
            autocomplete="off"
            (input)="zipValidation($event)"
            [maxlength]="5"
          />

          @if (
            dataVerificationForm.controls['zip'].invalid &&
            (dataVerificationForm.controls['zip'].dirty || dataVerificationForm.controls['zip'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-8 mb-15">
          <label for="city" class="theme-color">Stadt</label>
          <input
            formControlName="city"
            type="text"
            class="form-control form-control-lg"
            id="city"
            name="city"
            autocomplete="off"
          />

          @if (
            dataVerificationForm.controls['city'].invalid &&
            (dataVerificationForm.controls['city'].dirty || dataVerificationForm.controls['city'].touched)
          ) {
            <div class="text-danger">Pflichtfeld</div>
          }
        </div>

        <div class="col-12 mb-15">
          <label for="birthday" class="theme-color">Geburtsdatum</label>
          <input
            formControlName="birthday"
            type="text"
            class="form-control form-control-lg"
            id="birthday"
            mask="00.00.0000"
            [dropSpecialCharacters]="[]"
            [specialCharacters]="['.']"
            [showMaskTyped]="true"
          />
        </div>
      </div>
    </div>

    <div class="row mt-20 mt-md-5">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <div class="theme-secondary-color pointer-cursor text-16-mobile" (click)="triggerPreviousStep.emit()">
          Zurück
        </div>

        <button
          class="btn btn-rounded btn-purple ml-20 py-15 px-20"
          type="submit"
          [disabled]="dataVerificationForm.invalid"
          (click)="checkData()"
        >
          Weiter
        </button>
      </div>
    </div>
  </form>
</div>
