<div class="onboarding-profession-step-container">
  <div class="row">
    <div class="col-12">
      <h3 class="block-title font-size-h4 fw-400 mb-20">Wie ist dein aktueller beruflicher Status?</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-20">
      <div class="align-items-center">
        <span class="icon-container theme-color d-flex align-items-center gap-1">
          <i class="bi bi-info-circle"></i>
          Nur eine Auswahl m&ouml;glich!
        </span>
      </div>
    </div>
  </div>

  <div class="middle-content mb-15">
    <div class="row d-flex align-items-center justify-content-center flex-wrap stepper" style="gap: 0 5px">
      @for (entry of professionalStatus | keys; track entry) {
        <div class="mb-0" style="width: 47%">
          <div class="selection-wrapper">
            <label for="inputfield-{{ entry.key }}" class="selected-label">
              <input
                type="radio"
                name="inputfield"
                id="inputfield-{{ entry.key }}"
                [checked]="personProfile?.professionStatus === entry.key"
                (click)="setValue(entry.key)"
              />
              <div class="selected-content">
                <h4>{{ entry.value }}</h4>
              </div>
            </label>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-left py-2">
      <button
        type="button"
        class="btn btn-rounded theme-secondary-color pointer-cursor"
        (click)="triggerPreviousStep.emit()"
      >
        <i class="bi bi-arrow-left mr-2"></i> Zur&uuml;ck
      </button>
    </div>

    <div class="col-6 text-right">
      <button
        type="button"
        [disabled]="!personProfile?.professionStatus"
        class="btn btn-rounded btn-purple btn-modal"
        (click)="triggerNextStep.emit()"
      >
        Weiter
        <i class="bi bi-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>
