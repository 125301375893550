import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WelcomeMessageService {
  welcomeMessage: boolean = true;

  detectedContractsBanner: boolean = true;

  onBoardingState: boolean = true;
}
