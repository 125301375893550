import { Component, HostListener, Inject, isDevMode, OnInit } from '@angular/core';
import { WebSocketService } from '@vinlivt/websocket';

import { EffectiveTheme, UiChangeTriggersService } from '../../shared/global-store';

import {
  Advisor,
  CompanySettings,
  PartnerLinksModel,
  Person,
  StatisticsResponse,
  VinhubMessage,
} from '../../shared/model';
import {
  AUTH_SERVICE_TOKEN,
  AuthService,
  CompanySettingsService,
  StatisticService,
  UserSettingsService,
  VinhubService,
} from '../../shared/service';

import WithSubscription from '../../shared/utils/WithSubscriptions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class SidebarComponent extends WithSubscription(Object) implements OnInit {
  protected devMode: boolean = false;
  protected innerHeight: number = innerHeight;
  protected innerWidth: number = innerWidth;

  // protected logoLoading: boolean = true;
  protected logo: string = '';

  protected statisticsResponse: StatisticsResponse = new StatisticsResponse();
  protected companySettings: CompanySettings;
  protected person: Person = new Person();
  protected advisorInfo: Advisor;
  protected advisorLoading: boolean = true;
  protected scaleLink: string = '';

  protected unreadMessages: number;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private readonly userSettingsService: UserSettingsService,
    private readonly statisticService: StatisticService,
    private readonly companySettingsService: CompanySettingsService,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
    private readonly webSocketService: WebSocketService,
    private readonly vinhubService: VinhubService,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.windowSize();
  }

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      this.loadUserProfile();
      this.loadCompanySettings();
      this.loadStatistics();
      this.listenForNewMessages();
    }

    this.listenToSubject(this.userSettingsService.profileDataChangedSubject, (): void => {
      this.loadUserProfile();
      // this.loadCompanySettings();
    });

    this.listenToSubject(this.uiChangeTriggersService.effectiveThemeChanged, this.onThemeChange.bind(this));
    this.onThemeChange(this.uiChangeTriggersService.theme);

    if (isDevMode()) {
      this.devMode = true;
    }
  }

  private windowSize(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    this.onThemeChange(this.uiChangeTriggersService.theme);
  }

  private loadStatistics(): void {
    this.statisticService.loadStatistics().subscribe({
      next: (data: StatisticsResponse): void => {
        this.statisticsResponse = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });

    this.vinhubService.checkUnreadMessages().subscribe({
      next: (data: number): void => {
        this.unreadMessages = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private loadUserProfile(): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
        this.advisorInfo = data.advisor;
        this.replaceAdvisorWithSupervisor();

        this.advisorInfo.partnerLinks.forEach((partnerLink: PartnerLinksModel): void => {
          if (partnerLink?.partnerLinkType === 'RECOMMENDATION') {
            this.scaleLink = partnerLink.url;
          }
        });

        this.advisorLoading = false;

        // advisor info update subscription after consents
        setTimeout((): void => {
          this.userSettingsService.getUsersAdvisor().subscribe({
            next: (data: Advisor): void => {
              this.advisorInfo = data;
              this.replaceAdvisorWithSupervisor();
            },
            error: (error): void => {
              console.log(error);
            },
          });
        }, 1500);
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private replaceAdvisorWithSupervisor(): void {
    if (this.advisorInfo?.supervisor?.id) {
      this.advisorInfo.firstName = this.advisorInfo.supervisor.firstName;
      this.advisorInfo.lastName = this.advisorInfo.supervisor.lastName;
      this.advisorInfo.profilePictureUrl = this.advisorInfo.supervisor.profilePicture;
    }
  }

  private messageArrivedPlaySound(): void {
    const audio: HTMLAudioElement = new Audio();
    audio.src = 'assets/sounds/message-ring.mp3';
    audio.load();
    audio.play();
  }

  private listenForNewMessages(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<number>('chat/changed-unread-count'),
      (messageCount: number): void => {
        this.unreadMessages = messageCount;
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('chat/new-message'),
      (message: VinhubMessage): void => {
        if (message.authorId !== this.authService.userId) {
          this.unreadMessages++;
          this.messageArrivedPlaySound();
        }
      },
    );

    // this.listenToSubject(this.eventsManagerService.observeReadMessage(), () =>
    //   setTimeout(() => this.loadStatistics(), 800),
    // );
  }

  private loadCompanySettings(): void {
    this.companySettingsService.getCompanySettingsData().subscribe({
      next: (data: CompanySettings): void => {
        this.companySettings = data;

        if (this.companySettings?.companyLogoUrl && this.innerWidth >= 1400) {
          this.logo = this.companySettings.companyLogoUrl;
        } else {
          this.listenToSubject(this.uiChangeTriggersService.effectiveThemeChanged, this.onThemeChange.bind(this));
          this.onThemeChange(this.uiChangeTriggersService.theme);
        }

        // this.logoLoading = false;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private onThemeChange(theme: EffectiveTheme): void {
    if (this.innerWidth >= 1400) {
      this.logo = `https://static-assets.vinlivt.de/logo/b2c/${theme}%401x.png`;
    } else {
      this.logo = `https://static-assets.vinlivt.de/logo/icon/${theme}%401x.svg`;
    }
  }
}
